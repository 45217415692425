import { template as template_fc03e96a7c7d4c29a7aee458f03a5ae6 } from "@ember/template-compiler";
const FKText = template_fc03e96a7c7d4c29a7aee458f03a5ae6(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
