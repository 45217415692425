import { template as template_3d040065d9b141d1aa707e84497384a9 } from "@ember/template-compiler";
const FKLabel = template_3d040065d9b141d1aa707e84497384a9(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
