import { template as template_251518aa4b4643c2be27ec3f8b3513ae } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_251518aa4b4643c2be27ec3f8b3513ae(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
