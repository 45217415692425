import { template as template_33e2d65d3c6f4e0f8d8a4085f8d8dc7f } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_33e2d65d3c6f4e0f8d8a4085f8d8dc7f(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_33e2d65d3c6f4e0f8d8a4085f8d8dc7f(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_33e2d65d3c6f4e0f8d8a4085f8d8dc7f(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
