import { template as template_6d57e69cf99b48d49653b27a26266f34 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_6d57e69cf99b48d49653b27a26266f34(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
