import { template as template_71ae96bc34e345f9a4d8c51385ad62b0 } from "@ember/template-compiler";
const EmptyState = template_71ae96bc34e345f9a4d8c51385ad62b0(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
